<template>
	<div class="footer_wrapper">
		<div class="footer">
			<div class="left flex_grow">
				<img
					v-if="showDMixFooterLogo"
					class="max-w-[170px] w-full h-auto"
					src="/images/logo-dm.png"
				/>

				<template v-else>
					<span class="text-[12px] mr-[4px]">Powered by</span>
					<TengivaLogo
						color="black"
						class="w-[118px] h-[24px] mt-[4px]"
					/>
				</template>
			</div>
			<div class="middle flex_grow">© 2023 All rights reserved</div>
			<div class="right flex_grow">
				<a>Terms & conditions</a>
				<span class="spacer">|</span>
				<a>Privacy policy</a>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import TengivaLogo from "../TengivaLogo.vue";

const { organizationProfile } = storeToRefs(useOrgStore());
const { mode } = useRuntimeConfig().public;
const showDMixFooterLogo = computed(() => mode !== "dev" && organizationProfile.value?.slug === "demo-showroom-dmix");
</script>
<style lang="scss" scoped>
.footer_wrapper {
	@apply h-[80px] border-t border-neutral-100;
}
.footer {
	@apply flex items-center justify-between h-[80px] px-[40px] max-w-[1440px] mx-auto;
	@apply text-[12px] text-neutral-900 leading-[18px] font-normal;
	@apply mobile:h-[158px] mobile:flex-col mobile:py-[25px];

	.flex_grow {
		@apply flex flex-1 items-center;
	}

	.left {
		@apply justify-start;
	}

	.middle {
		@apply justify-center;
	}

	.right {
		@apply justify-end;
		.spacer {
			@apply text-neutral-200 mx-[4px];
		}
	}
}
</style>
