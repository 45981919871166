import type { RootNavItem } from "@/components/Navigation/types";

export const useNavigations = () => {
	const routes: RootNavItem[] = [
		{
			id: "products",
			icon: "md-menu",
			label: "components.navigations.label.products",
			showBadge: false,
			path: "/products",
			showOnTabbar: false,
			hasSubmenu: true,
			required_auth: false,
		},
		{
			id: "collections",
			icon: "md-grid_view",
			label: "components.navigations.label.collections",
			showBadge: false,
			path: "/collections",
			showOnTabbar: false,
			hasSubmenu: true,
			required_auth: false,
		},
		{
			id: "about-us",
			icon: "md-info",
			label: "components.navigations.label.about_us",
			showBadge: false,
			path: "/about-us",
			showOnTabbar: false,
			hasSubmenu: false,
			required_auth: false,
		},
		{
			id: "contact",
			icon: "md-mail",
			label: "components.navigations.label.contact",
			showBadge: false,
			path: "/contact",
			showOnTabbar: false,
			hasSubmenu: false,
			required_auth: false,
		},
		{
			id: "my-account",
			icon: "md-account_circle",
			label: "components.navigations.label.my_account",
			showBadge: false,
			path: "/about-us",
			showOnTabbar: false,
			hasSubmenu: true,
			required_auth: true,
		},
		// {
		// 	id: "language",
		// 	icon: "md-language",
		// 	label: "components.navigations.label.language",
		// 	showBadge: false,
		// 	path: "/english",
		// 	showOnTabbar: false,
		// 	hasSubmenu: true,
		// },
	];

	return {
		routes,
	};
};
