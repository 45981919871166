<template>
	<div class="submenu">
		<template v-if="data">
			<Products
				v-if="props.name === 'products'"
				:categories="data.categories"
				:org-slug="organizationProfile?.slug"
			/>
			<Collections
				v-if="props.name === 'collections'"
				:collections="data.collections"
				:org-slug="organizationProfile?.slug"
			/>
		</template>

		<Account v-if="props.name === 'my-account'" />
		<Language v-if="props.name === 'language'" />
	</div>
</template>

<script lang="ts" setup>
import Products from "./Products.vue";
import Collections from "./Collections.vue";
import Account from "./Account.vue";
import Language from "./Language.vue";
import type { CatalogCollection /*, Category */ } from "~/types/products";
const { /* listCategoriesForShowroom, */ listCollections } = usePM();
const orgStore = useOrgStore();

interface SubmenuProps {
	name: string;
}
const props = defineProps<SubmenuProps>();
const { organizationProfile } = storeToRefs(orgStore);
const data = ref<{ /* categories: Category[]; */ collections: CatalogCollection[] }>();

onMounted(async () => {
	await nextTick();
	const [/* categories, */ collections] = await Promise.all([
		// listCategoriesForShowroom(organizationProfile.value?.organization_id),
		listCollections(organizationProfile.value?.organization_id || ""),
	]);

	data.value = { /* categories, */ collections };
});
</script>

<style lang="scss" scoped>
.submenu {
	@apply cursor-auto text-black px-[40px] pt-[8px] overflow-auto h-full divide-y-[1px] divide-neutral-200;
	@apply mobile:px-0;
	:deep(.item) {
		@apply py-[32px] mobile:py-[20px];
		.title {
			@apply text-[24px] leading-[32px] font-bold mb-[8px];
		}
		.subtitle {
			@apply text-[20px] leading-[30px];
		}
		.description {
			@apply text-[14px] leading-[20px] text-neutral-700;
		}
		.links {
			@apply flex flex-col mt-[16px] space-y-[25px];
		}
		.action {
			@apply cursor-pointer text-blue-200 text-[14px] leading-[20px];
		}
	}
}
</style>
